<template>
  <div class="bc-404">
    <div class="bc-404__title">{{ $t('ERRORS.404.TITLE') }}</div>
    <div class="bc-404__subtitle">
      {{ $t('ERRORS.404.SUBTITLE') }}
    </div>
    <div class="bc-404__guide">{{ $t('ERRORS.404.BTN_TITLE') }}</div>
    <div class="bc-404__btn">
      <bc-button :color-type="'white'" @click="$router.back()">{{ $t('ERRORS.404.BTN_GO_BACK') }}</bc-button>
      <bc-button @click="$router.push('/')">{{ $t('ERRORS.404.BTN_GO_MAIN') }}</bc-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bc-404",
  components: {}
}

</script>

<style scoped lang="scss">
.bc-404 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 780px;
  padding: var(--header-height) 20px;

  &__title {
    font-size: 2em;
    margin-bottom: 30px;
  }
  &__subtitle {
    text-align: center;
    font-size: .8em;
    margin-bottom: 50px;
  }
  &__guide {
    color: $dark-gray;
    font-size: .75em;
    margin-bottom: 30px;
  }
  &__btn {
    display: flex;
    button {
      margin: 0 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .bc-404 {
    &__btn {
      flex-direction: column;
      button {
        margin-bottom: 20px;
      }
    }
  }
}
</style>